<template>
  <!--<secured-area>
    <left-aside-menu menu-title="Compétitions" menu-sub-title="(en cours)">
      <side-bar-menu-link :to="{name:'CompetitionCreate'}">Nouvelle compétition</side-bar-menu-link>
      <side-bar-menu-link :to="{name:'CompetitionsRunning'}">Compétitions en cours</side-bar-menu-link>
    </left-aside-menu>
  </secured-area>-->
  <router-view></router-view>
</template>

<script>
//import SecuredArea from '@/components/Security/SecuredArea.vue';
//import SideBarMenuLink from '@/components/Panels/SideBarMenuLink.vue';
//import LeftAsideMenu from '@/components/PageTemplates/LeftAsideMenu.vue';
export default{
//  components: { SecuredArea, SideBarMenuLink, LeftAsideMenu, },
//  mounted(){
//    this.$hideLoader();
//  }
}
</script>

